import Vue from 'vue'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "slider",
    props: {
        item: {
            type: Array,
            default: []
        }
    },
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            smallSliderKey: 0,
            bigSliderKey: 1,
            isOpenModal: false,
            smallSlider: {
                "infinite": true,
                "slidesToShow": 3,
                "slidesToScroll": 1,
                arrows: false,
                "vertical": true,
                "verticalSwiping": true,

            },
            bigSlider1: {
                "autoplay": false,
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "swipe": false,
                "draggable": false,
                arrows: false,
                "responsive": [
                    {
                        "breakpoint": 550,
                        "settings": {
                            swipe: true,
                            "draggable": true,
                            // "dots": true
                        }
                    },
                ]

            },
        }
    },

    created() {

    },
    watch: {
        'item': function () {
            this.forceRerender()
        }
    },
    computed: {
        ...mapGetters({})
    },
    beforeUpdate() {

    },
    methods: {
        ...mapMutations({
            reloadSlider: 'card/SET_PRODUCT_LOADING'
        }),
        forceRerender() {
            this.reloadSlider(true)

            this.smallSliderKey += 1
            this.bigSliderKey += 2

            setTimeout(() => {
                this.reloadSlider(false)
            }, 1500)

        },
        showNext() {
            this.$refs.smallCarousel.next()
        },
        showPrev() {
            this.$refs.smallCarousel.prev()
        },
        goTo(index) {
            this.$refs.bigCarousel.goTo(index)
        },
        openModal() {
            this.isOpenModal = true

        },
        closeModal() {
            this.isOpenModal = false
        },
        bigSliderNext() {
            this.$refs.bigCarousel.next()
        },
        bigSliderPrev() {
            this.$refs.bigCarousel.prev()
        }
    }
}
